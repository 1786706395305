import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaArrowsRotate, FaHandshake, FaPencil, FaTrash } from 'react-icons/fa6'
import { rootUrl } from '../../../helpers'
import { useAuth } from '../../../context/auth-context'
import { toast } from 'react-toastify'


export default function Matching() {

    const auth = useAuth();

    const [matched, setMatched] = useState([]);

  async  function getData(){
    try{

        const response = await axios.get(rootUrl(`/getMatched/1`)) 
            if(response.data.status  == 200){
                
                const mData = response.data.data
                const matches =[...new Set(mData.map(res=>res[0]))]
                var final = []
                matches.map((res)=>{

                    const mentor = mData.filter(a=>a.userId == mData.filter(a=>a[0] == res)[0].mentor)[0]
                    const mentee = mData.filter(a=>a.userId == mData.filter(a=>a[0] == res)[0].mentee)[0]
                    final.push({
                        mentor:mentor.firstName +' '+mentor.lastName,
                        mentee:mentee.firstName +' '+mentee.lastName
                    })
                })

                console.log(final)
                setMatched(final)

              }else{
                toast.error("Something went wrong")
              }
     

    }catch(e){
        toast.error(e?.message)
      }
                
    }

    useEffect(()=>{
        getData()
    },[])

    const [isLoading, setIsLoading] = useState(false);

    async function match() {
        setIsLoading(true); 

        try {
            const response = await axios.get(rootUrl(`/match/1`));
            if (response.data.status === 200) {
                toast.success("Successful");
                getData();
            } else {
                toast.error("Something went wrong");
            }
        } catch (e) {
            toast.error(e?.message);
        } finally {
            setIsLoading(false); // Re-enable the button
        }
    }
    

  return (
    <div className='bg-white p-4 rounded-lg shadow-lg'>
<div className='flex justify-between '>
    <div className='flex items-center font-semi-bold text-lg'>
            <FaHandshake className='mr-2' /> Matching
            
        </div>

        <button 
            onClick={match} 
            className={`flex items-center bg-red-500 text-white p-2 px-4 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} 
            disabled={isLoading}
        >
            <FaArrowsRotate /> Match
        </button>
         
    </div>

    <hr className='my-3' />

    <div className="container mx-auto p-4">
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className='bg-red-500 text-white p-3 text-left'>
                        
                        <th className="py-2 px-4 border-b">Sno</th>
                        <th className="py-2 px-4 border-b">Mentor</th>
                        <th className="py-2 px-4 border-b">Mentee</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {matched.map((item, index) => (
                        <tr key={index}>
                            <td className="py-2 px-4 border-b">{++index}</td>
                            <td className="py-2 px-4 border-b">{item.mentor}</td>
                            <td className="py-2 px-4 border-b">{item.mentee}</td>
                            <td className="py-2 px-4 border-b flex "> <FaTrash className='text-red-500 m-1'/> <FaPencil className='text-blue-500 m-1'/> </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>


    </div>
  )
}
