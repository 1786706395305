import React, { useState } from 'react'
import { FaChartBar, FaChartLine, FaChartPie, FaDatabase } from 'react-icons/fa'
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";

import BioData from './bioData';

export default function Reports() {

    const [activeTab, setActiveTab] = useState("biodata");
    const data = [
        {
          label: "BioData",
          value: "biodata",
          icon: FaDatabase,
          desc: <BioData/>,
        },
        {
          label: "Analytics",
          value: "analytics",
          icon: FaChartPie,
          desc: `For analytics`,
        },
        {
          label: "Summary",
          value: "summary",
          icon: FaChartLine,
          desc: `For Summary reports`,
        },
      ];

  return (

    <div className='bg-white p-4 rounded-lg shadow-lg'>
        <div className='flex items-center font-semi-bold text-lg'>
          <FaChartBar className='mr-2' /> Reports
        </div>
        <hr className='my-3' />

        <Tabs value="biodata">
            <TabsHeader className="bg-gray-100 rounded-lg">
                {data.map(({ label, value, icon }) => (
                <Tab    key={value}
                        value={value}
                        onClick={() => setActiveTab(value)}
                        className={`flex items-center gap-2 text-blue-gray-700 hover:bg-blue-gray-50 active:bg-blue-gray-100 rounded-lg p-2 transition
                            ${
                                activeTab === value
                                    ? "bg-white text-blue-gray-700"
                                    : "text-blue-gray-700 hover:bg-blue-gray-200 active:bg-blue-gray-300"
                            }
                        `}
             >
                    <div className="flex items-center gap-2">
                    {React.createElement(icon, { className: "w-5 h-5" })}
                    <span className="font-medium">{label}</span>
                    </div>
                </Tab>
                ))}
            </TabsHeader>
            <TabsBody>
                {data.map(({ value, desc }) => (
                <TabPanel key={value} value={value} className="p-4">
                     <p className="text-blue-gray-700">{desc}</p>
                </TabPanel>
                ))}
            </TabsBody>
    </Tabs>


    </div>
  )
}
