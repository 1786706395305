import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/auth-context'
import { calculateAge, rootUrl } from '../../../helpers';
import { toast } from 'react-toastify';
import { Spinner } from '@material-tailwind/react';
import Qdata from '../../../data/questions.json';
import DataTable from 'react-data-table-component';
import { utils, writeFile } from 'xlsx';
import { FaFileExport } from 'react-icons/fa';

export default function BioData() {
    const auth = useAuth();
    const [cohorts, setCohorts] = useState([]);
    const [bioData, setBioData] = useState([]);
    const [loader, setLoader] = useState(false);

    const datas = [...Qdata.Information, ...Qdata.Experience]

    const fetchData = async () => {
        try{
            const data = await axios.get(rootUrl(`/cohorts/${auth.user.cid}`))
            if(data.data.status == 200){
                setCohorts(data.data.data)
            }else{
                toast.error(data.data.message)
            }
        }catch(e){
            toast.error(e?.message)
        }
    }

    useEffect(()=>{
        fetchData()
    },[])

    const getBioData = async (event) =>{
        setLoader(true)
        const cohortId = event.target.value;
        if(cohortId !== ""){
            const data = await axios.get(rootUrl(`/getBioData/${cohortId}`))   
            setLoader(false) 
            setBioData(data.data.data)        
        } 
    }

    return (
        <div>
          {cohorts.length > 0 ? (
            <>
              <select
                className="border p-2 rounded border-gray-500"
                onChange={getBioData}
              >
                <option value="">Select cohort</option>
                {cohorts.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.name}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <>
              <select className="border p-2 rounded border-gray-500">
                <option value="">Loading cohorts...</option>
              </select>
            </>
          )}
      
          <hr className="my-3" />
          {bioData.otherDetails !== undefined ? (
            loader ? (
              <Spinner className="h-20 w-20 text-gray-300" />
            ) : (
              <>
             <MyDataTable bioData={bioData} datas={datas} />
              </>
            )
          ) : (
            <></>
          )}
        </div>
      );
      
}



const MyDataTable = ({ bioData, datas }) => {

 
   
    const columns = [
      {
        name: 'Names',
        selector: row => `${row?.firstName} ${row?.lastName}`,
        sortable: true,
      },
      {
        name: 'Age',
        selector: row => `${calculateAge(row?.dob)}`,
        sortable: true,
      },
      {
        name: 'Email',
        selector: row => `${row?.email}`,
        sortable: true,
      },
      {
        name: 'Phone Number',
        selector: row => `${row?.phoneNumber}`,
        sortable: true,
      },
      {
        name: 'Gender',
        selector: row => `${row?.gender}`,
        sortable: true,
      },
      {
        name: 'Field',
        selector: row => `${row?.field}`,
        sortable: true,
      },
      {
        name: 'Level',
        selector: row => `${Qdata.Qualification.filter(a=>a.value == row?.level)[0]?.name}`,
        sortable: true,
      },
      ...datas.map(res => ({
        name: res.addition,
        selector: row =>
          bioData.otherDetails.find(
            a => a.uid == row.userId && a.qid == res.id
          )?.response || "",
        sortable: true,
      })),
    ];
  
    const data = bioData.user.map(user => ({
      ...user,
      otherDetails: bioData.otherDetails.filter(
        detail => detail.uid == user.userId
      ),
    }));
  
    const exportToExcel = () => {
      const exportData = bioData.user.map(user => {
        const row = {
          Names: `${user?.firstName} ${user?.lastName}`,
          Age: `${calculateAge(user?.dob)}`,
          Email: `${user?.email}`,
          PhoneNumber: `${user?.phoneNumber}`,
          Gender: `${user?.gender}`,
          Field: `${user?.field}`,
          Level: `${Qdata.Qualification.filter(a=>a.value == user.level)[0]?.name}`,
        };
        datas.forEach(res => {
          const response = bioData.otherDetails.find(
            a => a.uid == user.userId && a.qid == res.id
          )?.response || "";
          row[res.addition] = response;
        });
        return row;
      });
  
      const worksheet = utils.json_to_sheet(exportData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "ExportedData.xlsx");
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: 'red', 
                fontWeight: 'bold',        
                fontSize: '16px', 
                color:'white'          
            },
        },
        cells: {
            style: {
                padding: '10px', 
            },
        },
    };
  
    return (
      <div>
        <button onClick={exportToExcel} className="mb-3 p-2 bg-blue-500 text-white rounded flex justify-center">
         <FaFileExport/> Export to Excel
        </button>
  
        <DataTable
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          pointerOnHover
          fixedHeader
          fixedHeaderScrollHeight="400px"
          selectableRows
          customStyles={customStyles}
          subHeader
          subHeaderComponent={<input type="text" className='border border-gray-500 p-2 rounded-lg shadow-lg' placeholder="Search" />}
          subHeaderAlign="right"
          persistTableHead
        />
      </div>
    );
  };